import { Group } from 'three';

import { GeoVirtualContext, GeoScene, GeoPerspectiveCamera } from '@wemap/ar';
import { Coordinates } from '@wemap/geo';
import { Itinerary } from '@wemap/routers';

abstract class ItineraryHandler {

    _itineraryGroup: Group;

    _geoVirtualContext: GeoVirtualContext;
    _scene: GeoScene;
    _camera: GeoPerspectiveCamera;

    _itineraryPromise = Promise.resolve();
    _itinerary: Itinerary | null = null;

    _startPromise = Promise.resolve();
    _startCoords: Coordinates | null = null;

    _endPromise = Promise.resolve();
    _endCoords: Coordinates | null = null;

    constructor(geoVirtualContext: GeoVirtualContext) {

        this._geoVirtualContext = geoVirtualContext;
        this._scene = geoVirtualContext.scene;
        this._camera = geoVirtualContext.camera;

        this._itineraryGroup = new Group();
        this._itineraryGroup.name = 'itinerary';

        this._scene.add(this._itineraryGroup);
    }


    set itinerary(itinerary: Itinerary | null) {

        this._itineraryPromise = this._itineraryPromise.then(() => { this._drawItinerary(itinerary); });

        if (itinerary) {
            this.start = itinerary.from;
            this.end = itinerary.to;
        } else {
            this.start = null;
            this.end = null;
        }
        this._itinerary = itinerary;
    }
    abstract _drawItinerary(itinerary: Itinerary | null): void;


    set start(coords: Coordinates | null) {
        if (coords === this._startCoords) {
            return;
        }
        this._startPromise = this._startPromise.then(async () => {
            await this._drawStart(coords);
        });
        this._startCoords = coords;
    }
    abstract _drawStart(coords: Coordinates | null): Promise<void>;


    set end(coords: Coordinates | null) {
        if (coords === this._endCoords) {
            return;
        }
        this._endPromise = this._endPromise.then(async () => {
            await this._drawEnd(coords);
        });
        this._endCoords = coords;
    }
    abstract _drawEnd(coords: Coordinates | null) : Promise<void>;
}

export default ItineraryHandler;
